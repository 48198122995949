import React, { useEffect, useRef } from "react";
import LandingPage from "../components/LandingPage";
import HowItWorks from "../components/HowitWorks";
import Billing from "../components/Billing";
import gsap from "gsap";
import Footer from "../components/Footer";
import Header from "../components/Header";
import HowAreWeDifferent from "../components/HowAreWeDifferent";

const Home: React.FC<{}> = () => {
  // const cursorRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   document.addEventListener("mousemove", (e) => {
  //     gsap.to(cursorRef.current, {
  //       x: e.clientX,
  //       y: e.clientY,
  //       duration: 0.3,
  //       ease: "power2.out",
  //     });
  //   });
  // }, []);

  return (
    <div className="bg-bakedPotato">
      {/* <div
        ref={cursorRef}
        className="fixed w-6 h-6 rounded-full bg-bakedPotato mix-blend-difference  z-50"
      ></div> */}
      <div>
        <Header />
      </div>
      <div className="min-h-screen">
        <LandingPage />
      </div>
      <div className="min-h-screen">
        <HowItWorks />
      </div>
      <div className="min-h-screen">
        <HowAreWeDifferent />
      </div>
      <div className="min-h-screen">
        <Billing />
      </div>
      <Footer />
    </div>
  );
};

export default Home;
