import React, { useEffect, useRef, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import LoginComponent from "../components/Login";

const LoginPage: React.FC = () => {
 

  return (
    <div className="min-h-screen bg-bakedPotato flex flex-col items-center justify-center">
      
      <div className="w-full">
        <Header />
        <LoginComponent />
        <Footer />
      </div>
    </div>
  );
};

export default LoginPage;
