import React, { useEffect, useState, useCallback } from "react";
import { Popover, Transition } from "@headlessui/react";
import { X } from "lucide-react";
import Alert from "./Alert";
import LoginComponent from "./Login";

const LoginPopover = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showALert, setShowAlert] = useState(false);

  const checklocalStorage = useCallback(() => {
    const email = localStorage.getItem("email");
    return !email || email === "";
  }, []);

  useEffect(() => {
    setIsOpen(checklocalStorage());

    const handleStorageChange = () => {
      setIsOpen(checklocalStorage());
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [checklocalStorage]);

  const handleCloseAlert = () => {
    setShowAlert(false);
  };
  const handleClose = () => {
    if (checklocalStorage()) {
      setShowAlert(true);
    } else {
      setIsOpen(false);
    }
  };


  return (
    <Popover>
      <Transition
        show={isOpen}
        enter="transition duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition duration-150 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          static
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
        >
          {showALert && (
            <Alert
              message="Please login to continue."
              onClose={handleCloseAlert}
            />
          )}
          <div className="bg-bakedPotato overflow-scroll rounded-lg shadow-xl overflow-hidden w-11/12 h-5/6 max-w-2xl max-h-[90vh]">
            <div className="flex justify-between items-center p-4 bg-creamyYellow">
              <h2 className="text-xl font-bold text-gray-800">Please Log In</h2>
              <div className="flex flex-row items-center">
                <img src="potato.gif" className="w-16" />
                <button
                  onClick={() => {
                    window.location.href = "/";
                  }}
                  aria-label="Back to home"
                  className="p-2 shadow-lg rounded-lg bg-bakedPotato hover:bg-mutedTeal focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Back to home?
                </button>
              </div>
              <button
                onClick={handleClose}
                aria-label="Close login popover"
                className="p-1 rounded-full hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <X className="h-5 w-5" />
              </button>
            </div>
            <div className="relative w-full mb-20 h-[calc(100%-56px)]">
            
              <LoginComponent />
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default LoginPopover;
