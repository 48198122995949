import React, { useEffect, useState } from "react";
import { X } from "lucide-react";

interface AlertProps {
  message: string;
  onClose: () => void;
}

const Alert: React.FC<AlertProps> = ({ message, onClose }) => {
  const [opacity, setOpacity] = useState(1);
  const fadeDuration = 5000;

  useEffect(() => {
    const fadeOutInterval = setInterval(() => {
      setOpacity((prev) => {
        if (prev > 0) {
          return prev - 0.02;
        } else {
          clearInterval(fadeOutInterval);
          return 0;
        }
      });
    }, fadeDuration / 50);

    const timer = setTimeout(() => {
      onClose();
    }, fadeDuration);

    return () => {
      clearInterval(fadeOutInterval);
      clearTimeout(timer);
    };
  }, [onClose]);

  const handleClose = () => {
    setOpacity(0);
    setTimeout(onClose, 300);
  };

  return (
    <div
      className={`fixed top-4 left-1/2 transform -translate-x-1/2 z-50 transition-opacity duration-300`}
      style={{ opacity }}
    >
      <div className="bg-creamyYellow text-black p-4 rounded-lg shadow-lg flex items-center space-x-3">
        <img
          src="https://img.icons8.com/ios-filled/50/000000/carrot.png"
          alt="Food Icon"
          className="w-8 h-8"
        />
        <div className="flex-grow">
          <p className="font-bold text-lg">{message}</p>
        </div>
        <button onClick={handleClose} className="text-xl font-bold">
          <X />
        </button>
      </div>
    </div>
  );
};

export default Alert;
