import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { apiUri } from "../constants";
import { motion } from "framer-motion";
import LoadingIcon from "../components/LoadingIcon";
import Alert from "../components/Alert";
import { Eye, Copy, Bookmark, ChevronDown, ChevronUp } from "lucide-react";
import { Search, FileUp, Book, MessageSquare } from "lucide-react";
import ViewFullCasePopover from "../components/ViewFullCasePopover";
import InteractWithCase from "../components/InteractionPopover";
import Header from "../components/Header";
import Footer from "../components/Footer";
import LoginPopover from "../components/LoginPopover";
import HowToUsePopover from "../components/HowToUsePopover";
import CaseCard from "../components/CaseCard";
import LoadingCard from "../components/LoadingCard";
import CaseLawSection from "../components/CaseLawSection";
import LegalAnalysisSection from "../components/KeywordSection";

type CaseData = {
  "Case Title": string;
  "Parties involved": string[];
  "case laws and sections referred": string[];
  "facts of the case": string[];
  "arguments of both parties": object;
  "final decision": string;
};

const Research: React.FC<{}> = () => {
  const [query, setQuery] = useState("");
  const [pageNum, setPageNum] = useState(0);
  const [caseData, setCaseData] = useState<CaseData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  var FinalInfor: CaseData[] = [];

  const [fullCaseFile, setFullCaseFile] = useState<string[]>([]);
  const [pdfFile, setPdfFile] = useState<File | null>(null);
  const [alert, setAlert] = useState(false);
  const email = localStorage.getItem("email") || "";
  const [keywords, setKeywords] = useState<string[]>([]);
  const [bnsRefs, setBnsRefs] = useState<string[]>([]);
  const [bnsContextRefs, setbnsContextRefs] = useState<string[]>([]);

  const [loginPopoverOpen, setLoginPopoverOpen] = useState(false);

  async function processQueries(queries: string[]) {
    // setIsLoading(true);
    setCaseData(new Array(queries.length * 2).fill(null));

    setFullCaseFile(new Array(queries.length * 2).fill(null));

    let cardIndex = -1;

    for (let i = 0; i < queries.length; i++) {
      // break
      try {
        const response = await axios.post(`${apiUri}/searchKanoon`, {
          query: queries[i],
          Num: pageNum,
        });

        const caseDocs = response.data.data.docs;

        for (let j = 0; j < Math.min(2, caseDocs.length); j++) {
          try {
            const caseDetailCurrent = await axios.post(`${apiUri}/fetchdoc`, {
              docId: caseDocs[j].tid,
            });

            const doc = caseDetailCurrent.data.data.doc;
            setFullCaseFile((fullCaseFile) => {
              const updatedFile = [...fullCaseFile];
              updatedFile[i * 2 + j] = doc;

              return updatedFile;
            });

            //    console.log(doc)
            //     console.log(fullCaseFile)
            const MAX_SEGMENT_SIZE = 80000;

            const segments: string[] = [];
            let currentIndex = 0;

            while (currentIndex < doc.length) {
              const segment = doc.slice(
                currentIndex,
                currentIndex + MAX_SEGMENT_SIZE
              );
              segments.push(segment);
              currentIndex += MAX_SEGMENT_SIZE;
            }

            const extractions = await Promise.all(
              segments.map((segment) =>
                axios.post(`${apiUri}/extractor`, { detail: segment })
              )
            );

            const CombinedInfo = await axios.post(`${apiUri}/combinator`, {
              segment: extractions.map((e) => e.data.data),
            });

            setCaseData((prevData) => {
              const newData = [...prevData];
              newData[i * 2 + j] = CombinedInfo.data.data[0];
              return newData;
            });

            cardIndex++;
          } catch (error) {
            // console.log("Error during fetching doc: ", error);
          }
        }
      } catch (error) {
        // console.log('Error during search:', error);
      }
    }

    setIsLoading(false);
  }

  const handleSearch = async (e: React.FormEvent) => {
    e.preventDefault();
    // setIsLoading(true);

    try {
      var whatToSearch: string[] = [];

      // Run both axios requests in parallel using Promise.all()
      const [contextResponse, mindSearcherResponse] = await Promise.all([
        axios.post(`${apiUri}/getContext`, { userInput: query }),
        axios.post(`${apiUri}/mindSearcher`, { caseInput: query }),
      ]);

      // Handle contextResponse
      setKeywords(contextResponse.data.data);
      try {
        var whatToSearch: string[] = [];

        // Run both fetching of related laws and processing of mindSearcherResponse in parallel
        await Promise.all([
          // Fetch related laws
          (async () => {
            try {
              // console.log(contextResponse.data.data)
              const re = await axios.post(`${apiUri}/getRelatedLaws`, {
                questions: contextResponse.data.data,
              });
              setBnsRefs(re.data.data.responses);
              // setbnsContextRefs(re.data.data.contexts);
            } catch (error) {
              console.log("Error 1025");
            }
          })(),

          // Process mindSearcherResponse
          (async () => {
            // Handle mindSearcherResponse
            whatToSearch = mindSearcherResponse.data.data;
            const queries = whatToSearch;
            await processQueries(queries);
          })(),
        ]);

        console.log("Both fetching and processing completed in parallel");
      } catch (error) {
        console.log("Error during parallel fetching and processing:", error);
      }
    } catch (error) {
      console.log("Error during parallel execution");
    }
  };

  const convertToString = (value: any): string => {
    return typeof value === "object" ? JSON.stringify(value, null, 2) : value;
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setPdfFile(e.target.files[0]);
    }
  };

  async function summarizeDocument(pageContent: string) {
    const chunkSize = 100000;
    let summaries: string[] = [];

    for (let i = 0; i < pageContent.length; i += chunkSize) {
      const chunk = pageContent.substring(i, i + chunkSize);

      try {
        const response = await axios.post(`${apiUri}/documentSummarizer`, {
          caseInput: chunk,
        });

        if (response.data) {
          summaries.push(response.data.data);
        } else {
          //console.log(`No summary returned for chunk ${i / chunkSize + 1}`);
        }
      } catch (error) {
        console.error(`Error summarizing chunk ${i / chunkSize + 1}:`, error);
      }
    }
    return summaries.join(" ");
  }

  const handleIngest = async (e: React.FormEvent) => {
    e.preventDefault();

    if (pdfFile) {
      // setIsLoading(true)
      try {
        const formData = new FormData();
        formData.append("document", pdfFile);

        const response = await axios.post(`${apiUri}/upload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        //console.log('Response:', response);
      } catch (error) {
        //console.log('Error:', error);
      }
    } else {
      setAlert(true);
    }

    //    setIsLoading(false)
  };
  useEffect(() => {
    if (alert) {
      const timer = setTimeout(() => setAlert(false), 2000);
      return () => clearTimeout(timer);
    }
  }, [alert]);

  const handleResearchFile = async (e: React.FormEvent) => {
    e.preventDefault();

    if (pdfFile) {
      // setIsLoading(true)
      try {
        const formData = new FormData();
        formData.append("document", pdfFile);

        formData.append("email", email);

        const response = await axios.post(`${apiUri}/pdfExtract`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        //console.log(response.data.data)
        const j = JSON.stringify(response.data.data, null);
        const obj = JSON.parse(j);

        if (obj.pageContent) {
          const formattedContent = obj.pageContent.replace(/\s+/g, " ").trim();

          obj.pageContent = formattedContent;
        }

        const summrizedContent = await summarizeDocument(obj.pageContent);

        setQuery(summrizedContent);

        handleSearch(e);
      } catch (error) {
        //console.log('Error:', error);
      }
    } else {
      setAlert(true);
    }
  };

  // const cursorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      localStorage.getItem("email") == "" ||
      localStorage.getItem("email") == null
    ) {
      setLoginPopoverOpen(true);
    }
    // document.addEventListener("mousemove", (e) => {
    //   gsap.to(cursorRef.current, {
    //     x: e.clientX,
    //     y: e.clientY,
    //     duration: 0.3,
    //     ease: "power2.out",
    //   });
    // });
  }, []);

  const handleClick = () => {
    setIsLoading(false);
  };

  return (
    <div className="flex flex-col min-h-screen bg-creamyYellow">
      {loginPopoverOpen && <LoginPopover />}
      {!loginPopoverOpen && <HowToUsePopover />}

      <div className="bg-slate-900 text-white p-8">
        <div className="max-w-7xl mx-auto">
          <div className="mb-6 flex items-center space-x-4">
            <h1 className="text-4xl font-semibold mb-4">Legal Case Research</h1>
            <img
              src="nerdPotato.png"
              alt="Logo"
              className="w-16 h-16 object-contain"
            />
          </div>
          <p className="text-lg text-gray-300 leading-relaxed">
            Enter your case details or upload a document, and our advanced
            research tool will analyze heaps of cases to provide you with the
            most relevant precedents.
          </p>
          <p className="text-sm mt-4 text-gray-400">
            Please note: Analysis may take 3-5 minutes to fully load and ensure
            comprehensive results.
          </p>
        </div>
      </div>

      <div className="w-full px-4 py-8">
        {/* Container for Search Form */}
        <div className="mb-8 border-2 bg-black rounded-xl">
          <div className="p-6">
            <form onSubmit={handleSearch} className="space-y-6">
              {/* Textarea for Case Facts */}
              <div>
                <textarea
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  placeholder="Enter case facts/scenario/description. Leave the rest to us."
                  className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 h-32 resize-none"
                  rows={4}
                  style={{ maxHeight: "150px" }}
                />
              </div>

              {/* Buttons Row: Search & Upload */}
              <div className="flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-4">
                {/* Search Button */}
                <button
                  type="submit"
                  onClick={handleClick}
                  className="flex items-center w-full sm:w-auto justify-center px-6 py-2.5 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200"
                >
                  <Search className="w-4 h-4 mr-2" />
                  Search Cases
                </button>

                {/* Uncommented for Upload & Analyze Button */}
                {/* 
          <form
            onSubmit={handleResearchFile}
            className="flex-1 flex items-center space-x-4 w-full sm:w-auto"
          >
            <input
              type="file"
              onChange={handleFileChange}
              accept=".pdf"
              className="block w-full text-sm text-gray-500 file:mr-4 file:py-2.5 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-medium file:bg-gray-100 file:text-gray-700 hover:file:bg-gray-200"
            />
            <button
              type="submit"
              className="px-6 py-2.5 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors duration-200 w-full sm:w-auto"
            >
              Upload & Analyze
            </button>
          </form> 
          */}
              </div>
            </form>
          </div>
        </div>

        {/* Loading Indicator */}
        {isLoading ? (
          <LoadingIcon text="🍳 Please enter the case facts/scenario/description above. We are not a search engine. Give us 5-7 minutes!" />
        ) : (
          // Results Section (Legal Analysis and Related Cases)
          <div className="bg-white rounded-lg shadow p-4 space-y-6">
            {/* Legal Analysis Section */}
            <LegalAnalysisSection keywords={keywords} bnsRefs={bnsRefs} />

            {/* Related Cases Heading */}
            <h1 className="text-2xl font-semibold p-4 text-center sm:text-left">
              🥔 Past Related Cases:
            </h1>

            {/* Case Law Section */}
            <CaseLawSection caseData={caseData} fullCaseFiles={fullCaseFile} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Research;
