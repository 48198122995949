import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  ChevronUp,
  ChevronDown,
  MessageSquare,
  FileText,
  Users,
  Gavel,
  Book,
  Scale,
  Loader2,
  ChevronLeft,
  Menu,
} from "lucide-react";
import InteractWithCase from "./InteractionPopover";
import { Dialog } from "@headlessui/react";

// Types
type CaseData = {
  "Case Title": string;
  "Parties involved": object;
  "case laws and sections referred": string[];
  "facts of the case": string[];
  "arguments of both parties": object;
  "final decision": string;
};

interface CaseLawSectionProps {
  caseData: CaseData[];
  fullCaseFiles: string[];
}

// Case List Item Component
const CaseListItem: React.FC<{
  caseItem: CaseData;
  isSelected: boolean;
  onClick: () => void;
}> = ({ caseItem, isSelected, onClick }) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.3 }}
    className={`p-4 cursor-pointer rounded-lg mb-2 border-l-4
      hover:bg-gray-50 transition-all 
      ${isSelected ? "border-blue-600 bg-gray-50" : "border-transparent"}`}
    onClick={onClick}
  >
    <h3 className="font-medium text-gray-900">{caseItem["Case Title"]}</h3>
  </motion.div>
);

// Loading Components
const LoadingCard = () => (
  <div className="p-4 border-l-4 border-gray-200 rounded-lg mb-2">
    <div className="space-y-3 animate-pulse">
      <div className="h-6 w-3/4 bg-gray-200 rounded"></div>
      <div className="h-4 w-1/2 bg-gray-200 rounded"></div>
    </div>
  </div>
);

const LoadingContent = () => (
  <div className="space-y-6 p-4">
    <div className="h-8 w-3/4 bg-gray-200 rounded animate-pulse"></div>
    <div className="space-y-4">
      {[1, 2, 3].map((i) => (
        <div key={i} className="space-y-2">
          <div className="h-6 w-full bg-gray-200 rounded animate-pulse"></div>
          <div className="h-4 w-5/6 bg-gray-200 rounded animate-pulse"></div>
        </div>
      ))}
    </div>
  </div>
);

// Section Content Component
const SectionContent: React.FC<{
  section: string;
  content: any;
}> = ({ section, content }) => {
  if (Array.isArray(content)) {
    return (
      <div className="space-y-3 overflow-scroll h-[300px]">
        {content.map((item, index) => (
          <div
            key={index}
            className="p-4 bg-gray-50 rounded-lg text-gray-700 border border-gray-100"
          >
            {item}
          </div>
        ))}
      </div>
    );
  }

  if (section === "Parties involved" && typeof content === "object") {
    return (
      <div className="space-y-4 overflow-scroll h-[300px]">
        {Object.entries(content).map(([party, argument]) => (
          <div
            key={party}
            className="rounded-lg border border-gray-200 overflow-hidden"
          >
            <div className="bg-gray-50 px-4 py-2 border-b border-gray-200">
              <h4 className="font-medium text-gray-900">{party}</h4>
            </div>
            <div className="p-4 bg-white">
              <p className="text-gray-700">{argument as string}</p>
            </div>
          </div>
        ))}
      </div>
    );
  }

  if (section === "arguments of both parties" && typeof content === "object") {
    return (
      <div className="space-y-4 overflow-scroll h-[300px]">
        {Object.entries(content).map(([party, argument]) => (
          <div
            key={party}
            className="rounded-lg border border-gray-200 overflow-hidden"
          >
            <div className="bg-gray-50 px-4 py-2 border-b border-gray-200">
              <h4 className="font-medium text-gray-900">{party}</h4>
            </div>
            <div className="p-4 bg-white">
              <p className="text-gray-700">{argument as string}</p>
            </div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <p className="text-gray-700 p-4 bg-gray-50 rounded-lg overflow-scroll h-[300px]">
      {content}
    </p>
  );
};

// Case Details Section Component
const CaseDetailsSection: React.FC<{
  section: keyof CaseData;
  content: any;
  isExpanded: boolean;
  onToggle: () => void;
}> = ({ section, content, isExpanded, onToggle }) => {
  const icons = {
    "parties involved": Users,
    "case laws and sections referred": Book,
    "facts of the case": FileText,
    "arguments of both parties": Scale,
    "final decision": Gavel,
  };

  const Icon = icons[section as keyof typeof icons] || FileText;
  const iconColors = {
    "parties involved": "text-blue-600",
    "case laws and sections referred": "text-purple-600",
    "facts of the case": "text-green-600",
    "arguments of both parties": "text-orange-600",
    "final decision": "text-red-600",
  };

  return (
    <div className="mb-6 rounded-lg border border-gray-200 overflow-scroll">
      <button
        className={`
            w-full p-4 text-left transition-colors
            hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-inset
            ${isExpanded ? "bg-gray-50" : "bg-white"}
          `}
        onClick={onToggle}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-3">
            <div
              className={`${iconColors[section as keyof typeof iconColors]}`}
            >
              <Icon className="w-5 h-5" />
            </div>
            <span className="text-base font-medium text-gray-900 capitalize">
              {section}
            </span>
          </div>
          {isExpanded ? (
            <ChevronUp className="w-5 h-5 text-gray-500" />
          ) : (
            <ChevronDown className="w-5 h-5 text-gray-500" />
          )}
        </div>
      </button>

      <AnimatePresence initial={false}>
        {isExpanded && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            className="overflow-scroll border-t border-gray-200"
          >
            <div className="p-4">
              <SectionContent section={section} content={content} />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

// Document Viewer Component
const DocumentViewer: React.FC<{
  documentString?: string;
  isLoading: boolean;
}> = ({ documentString, isLoading }) => (
  <div className="h-[500px] rounded-lg bg-white p-4 shadow-sm overflow-scroll">
    {isLoading ? (
      <div className="h-full flex items-center justify-center">
        <div className="flex flex-col items-center space-y-4 text-gray-500">
          <Loader2 className="w-8 h-8 animate-spin" />
          <p>Loading document...</p>
        </div>
      </div>
    ) : (
      <div className="h-full flex flex-col">
        <h3 className="text-lg font-medium text-gray-900 mb-4 pb-3 border-b">
          Full Case Document
        </h3>
        <div className="flex-1 overflow-y-auto">
          <div
            dangerouslySetInnerHTML={{ __html: documentString || "" }}
            className="prose prose-gray max-w-none"
          />
        </div>
      </div>
    )}
  </div>
);

// Main Component
const CaseLawSection: React.FC<CaseLawSectionProps> = ({
  caseData,
  fullCaseFiles,
}) => {
  const [selectedCaseIndex, setSelectedCaseIndex] = useState<number>(0);
  const [expandedSection, setExpandedSection] = useState<string | null>(
    "facts of the case"
  );
  const [showInteraction, setShowInteraction] = useState<boolean>(false);

  const selectedCase = caseData[selectedCaseIndex];

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [showCaseList, setShowCaseList] = useState(true);
  const [showDetails, setShowDetails] = useState(false);

  return (
    <div className="h-[calc(100vh-64px)] flex flex-col lg:flex-row relative bg-gray-50">
      {/* Mobile Header */}
      <div className="lg:hidden flex items-center justify-between px-4 py-2 bg-gray-800 text-white">
        <button
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          className="p-2 rounded-md hover:bg-gray-700"
        >
          <Menu className="w-6 h-6" />
        </button>
        <h1 className="text-lg font-semibold">Legal Case Manager</h1>
        <button
          onClick={() => setShowDetails(!showDetails)}
          className="p-2 rounded-md hover:bg-gray-700"
        >
          <MessageSquare className="w-6 h-6" />
        </button>
      </div>

      {/* Mobile Navigation Drawer */}
      <Dialog
        open={mobileMenuOpen}
        onClose={() => setMobileMenuOpen(false)}
        className="fixed inset-0 z-40 lg:hidden"
      >
        {/* <Dialog.Overlay className="fixed inset-0 bg-black/30" /> */}
        <div className="fixed inset-y-0 left-0 w-72 bg-white shadow-xl">
          <div className="h-full flex flex-col">
            <div className="p-4 border-b border-gray-200 flex justify-between items-center">
              <h2 className="text-lg font-semibold">Related Cases</h2>
              <button
                onClick={() => setMobileMenuOpen(false)}
                className="p-2 rounded-md hover:bg-gray-100"
              >
                <ChevronLeft className="w-5 h-5" />
              </button>
            </div>
            <div className="flex-1 overflow-y-auto">
              <div className="w-full lg:w-1/4 border-b lg:border-b-0 lg:border-r border-gray-200 overflow-y-auto">
                <div className="p-4">
                  <h2 className="text-lg font-semibold text-gray-900 mb-4">
                    Related Cases
                  </h2>
                  {/* Map through case data and render list items or loading state */}
                  {caseData.map((caseItem, index) =>
                    caseItem ? (
                      <CaseListItem
                        key={index}
                        caseItem={caseItem}
                        isSelected={selectedCaseIndex === index}
                        onClick={() => setSelectedCaseIndex(index)}
                      />
                    ) : (
                      <LoadingCard key={index} />
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      {/* Desktop Case List */}
      <div
        className={`hidden lg:block w-72 bg-white border-r border-gray-200 overflow-y-auto`}
      >
        <div className="p-4">
          <h2 className="text-lg font-semibold text-gray-900 mb-4">
            Related Cases
          </h2>
          <div className="space-y-2">
            {caseData.map((caseItem, index) =>
              caseItem ? (
                <CaseListItem
                  key={index}
                  caseItem={caseItem}
                  isSelected={selectedCaseIndex === index}
                  onClick={() => setSelectedCaseIndex(index)}
                />
              ) : (
                <LoadingCard key={index} />
              )
            )}
          </div>
        </div>
      </div>

      {/* Main Content Area */}
      <div className="flex-1 min-w-0 flex flex-row">
        {/* Document Viewer */}
        <div className="flex-1 min-w-0 bg-white">
          <div className="h-full p-4 md:p-6">
            <div className="h-full rounded-lg bg-white shadow-sm overflow-hidden">
              {showInteraction ? (
                <div className="h-full flex flex-col">
                  <div className="border-b border-gray-200 p-4">
                    <h3 className="text-lg font-medium text-gray-900">
                      Case Analysis Interface
                    </h3>
                  </div>
                  <div className="flex-1 overflow-y-auto">
                    <InteractWithCase
                      document={fullCaseFiles[selectedCaseIndex]}
                      onClose={() => setShowInteraction(false)}
                    />
                  </div>
                </div>
              ) : (
                <div className={`${showDetails ? "hidden" : "block"}`}>
                  <DocumentViewer
                    documentString={fullCaseFiles[selectedCaseIndex]}
                    isLoading={!fullCaseFiles[selectedCaseIndex]}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Case Details Sidebar */}
        <div
          className={`
          ${showDetails ? "block" : "hidden"} 
          lg:block 
          w-full md:w-80 
          border-l border-gray-200 
          bg-white
          overflow-scroll
        `}
        >
          <div className="p-4 md:p-6">
            {selectedCase ? (
              <AnimatePresence mode="wait">
                <motion.div
                  key={selectedCaseIndex}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.2 }}
                >
                  <div className="sticky top-0 bg-white pb-4 mb-6 border-b border-gray-200">
                    <div className="flex justify-between items-center">
                      <h2 className="text-xl font-semibold text-gray-900 truncate">
                        {selectedCase["Case Title"]}
                      </h2>
                      <button
                        onClick={() => setShowInteraction(!showInteraction)}
                        className="flex items-center px-3 py-1.5 text-sm bg-blue-50 text-blue-600 rounded-md hover:bg-blue-100 transition-colors"
                      >
                        <MessageSquare className="w-4 h-4 mr-1.5" />
                        {showInteraction ? "View" : "Interact"}
                      </button>
                    </div>
                  </div>

                  <div className="space-y-4">
                    {(
                      Object.keys(selectedCase) as Array<
                        keyof typeof selectedCase
                      >
                    )
                      .filter((section) => section !== "Case Title")
                      .map((section) => (
                        <CaseDetailsSection
                          key={section}
                          section={section}
                          content={selectedCase[section]}
                          isExpanded={expandedSection === section}
                          onToggle={() =>
                            setExpandedSection(
                              expandedSection === section ? null : section
                            )
                          }
                        />
                      ))}
                  </div>
                </motion.div>
              </AnimatePresence>
            ) : (
              <LoadingContent />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseLawSection;
