import React from "react";

const Footer: React.FC = () => {
  return (
    <footer className="bg-lightSkin border-t border-creamyYellow text-white py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-6xl mx-auto flex flex-col sm:flex-row justify-between items-start">
        <div className="flex items-center mb-8 sm:mb-0">
          <img src="/logo.png" alt="Logo" className="w-24" />
        </div>

        <div className="grid grid-cols-2 gap-8 sm:flex sm:space-x-12">
          <div>
            <h4 className="text-xl font-semibold mb-4">Tools</h4>
            <ul className="space-y-2">
              <li>
                <a
                  href="/lawPotato"
                  className="hover:text-creamyYellow transition"
                >
                  LawPotato
                </a>
              </li>
            </ul>
          </div>

          <div>
            <h4 className="text-xl font-semibold mb-4">More</h4>
            <ul className="space-y-2">
              <li>
                <a href="/terms" className="hover:text-creamyYellow transition">
                  Terms & Conditions
                </a>
              </li>
              <li>
                <a
                  href="/privacy"
                  className="hover:text-creamyYellow transition"
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  href="/contactUs"
                  className="hover:text-creamyYellow transition"
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="mt-12 border-t border-creamyYellow pt-6 text-center">
        <p className="text-lg">
          © {new Date().getFullYear()} TAK Law Labs AI Pvt Ltd. All rights
          reserved.
        </p>
        <p className="text-sm mt-2">
          Empowering legal professionals with AI-driven solutions
        </p>
      </div>
    </footer>
  );
};

export default Footer;
