import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Book, ArrowRight, Tag, Loader2 } from 'lucide-react';

const KeywordCard: React.FC<{ keyword: string; index: number }> = ({ keyword, index }) => (
  <motion.li
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.3, delay: index * 0.1 }}
    className="group relative"
  >
    <div className="px-4 py-2 bg-white border border-gray-200 rounded-lg 
                    shadow-sm hover:shadow-md transition-all duration-300
                    hover:border-blue-200 cursor-pointer">
      <div className="flex items-center space-x-2">
        <Tag className="w-4 h-4 text-blue-500" />
        <span className="text-gray-700 font-medium">{keyword}</span>
      </div>
    </div>
  </motion.li>
);

const ReferenceCard: React.FC<{
  keyword: string;
  reference?: string;
  index: number;
}> = ({ keyword, reference, index }) => (
  <motion.li
    initial={{ opacity: 0, x: -20 }}
    animate={{ opacity: 1, x: 0 }}
    transition={{ duration: 0.3, delay: index * 0.1 }}
    className="relative"
  >
    <div className="bg-white border border-gray-200 rounded-lg p-6 
                    shadow-sm hover:shadow-md transition-all duration-300
                    hover:border-blue-200">
      <div className="flex items-start justify-between">
        <div className="space-y-2">
          <div className="flex items-center space-x-2">
            <Book className="w-5 h-5 text-blue-500" />
            <h3 className="font-semibold text-gray-900">{keyword}</h3>
          </div>
          
          {/* {reference ? (
            <div className="pl-7">
              <div className="text-sm text-gray-600 bg-gray-50 p-3 rounded-md border border-gray-100">
                <span className="font-medium text-gray-700">Reference:</span> {reference}
              </div>
            </div>
          ) : (
            <div className="pl-7 flex items-center space-x-2 text-gray-500">
              <Loader2 className="w-4 h-4 animate-spin" />
              <span className="text-sm italic">Loading reference...</span>
            </div>
          )} */}
        </div>
        <ArrowRight className="w-5 h-5 text-gray-400 group-hover:text-blue-500 transition-colors" />
      </div>
    </div>
  </motion.li>
);

const LoadingAnimation: React.FC = () => (
  <div className="flex items-center justify-center p-8">
    <div className="flex flex-col items-center space-y-4">
      <div className="relative">
        <Loader2 className="w-8 h-8 animate-spin text-blue-500" />
      </div>
      <p className="text-gray-600 animate-pulse">Processing your request...</p>
    </div>
  </div>
);

interface LegalAnalysisSectionProps {
  keywords: string[];
  bnsRefs: (string | undefined)[];
}

const LegalAnalysisSection: React.FC<LegalAnalysisSectionProps> = ({ keywords, bnsRefs }) => {
  if (keywords.length === 0) {
    return <LoadingAnimation />;
  }

  return (
    <div className="space-y-8 p-6">
      <section>
        <div className="flex items-center space-x-2 mb-6">
          <h2 className="text-xl font-semibold text-gray-800">
            Key Legal Concepts
          </h2>
          <span className="px-2 py-1 bg-blue-50 text-blue-600 text-sm rounded-md">
            {keywords.length} found
          </span>
        </div>
        <ul className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
          <AnimatePresence>
            {keywords.map((keyword, index) => (
              <KeywordCard key={index} keyword={keyword} index={index} />
            ))}
          </AnimatePresence>
        </ul>
      </section>

      <section className="mt-8">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-xl font-semibold text-gray-800">
            Legal Analysis & References
          </h2>
          <div className="text-sm text-gray-500">
            {bnsRefs.filter(Boolean).length} of {keywords.length} references loaded
          </div>
        </div>
        <ul className="space-y-4">
          <AnimatePresence>
            {keywords.map((keyword, index) => (
              <ReferenceCard
                key={index}
                keyword={keyword}
                reference={bnsRefs[index]}
                index={index}
              />
            ))}
          </AnimatePresence>
        </ul>
      </section>
    </div>
  );
};

export default LegalAnalysisSection;