import { motion } from "framer-motion";
import { Scale } from "lucide-react";

interface LoadingIconProps {
  text?: string;
}

const LoadingIcon: React.FC<LoadingIconProps> = ({ text = "Loading.." }) => {
  return (
    <div className="flex flex-col items-center justify-center min-h-[400px]">
      <div className="relative">
        {/* Animated Legal Document Lines */}
        <div className="relative w-32 h-40 bg-white rounded-lg shadow-lg overflow-hidden">
          {[...Array(8)].map((_, i) => (
            <motion.div
              key={i}
              className="h-2 rounded-full my-2 mx-4"
              style={{
                backgroundColor: i === 0 ? "#7C3AED" : "#E2E8F0",
                width: i === 0 ? "70%" : `${Math.random() * 40 + 50}%`,
              }}
              animate={{
                opacity: [0.5, 1, 0.5],
                x: [-4, 0, -4],
              }}
              transition={{
                duration: 2,
                repeat: Infinity,
                delay: i * 0.2,
              }}
            />
          ))}

          {/* Scanning Line Effect */}
          <motion.div
            className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-transparent via-purple-500 to-transparent"
            animate={{
              y: [0, 160, 0],
            }}
            transition={{
              duration: 2,
              repeat: Infinity,
              ease: "linear",
            }}
          />
        </div>

        {/* Scale of Justice Overlay */}
        <motion.div
          className="absolute -top-4 -right-4 bg-purple-800 rounded-full p-3 shadow-lg"
          animate={{
            rotate: [0, 360],
          }}
          transition={{
            duration: 4,
            repeat: Infinity,
            ease: "linear",
          }}
        >
          <Scale className="w-6 h-6 text-white" />
        </motion.div>
      </div>

      {/* Loading Text */}
      {text && (
        <motion.div
          className="mt-8 text-center"
          animate={{
            opacity: [0.5, 1, 0.5],
          }}
          transition={{
            duration: 2,
            repeat: Infinity,
            ease: "easeInOut",
          }}
        >
          <div className="text-xl font-semibold text-purple-800">{text}</div>
          <div className="text-lg text-black mt-1">
            Ready to process legal documents...
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default LoadingIcon;
