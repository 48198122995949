import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const Billing: React.FC = () => {
  const sectionRef = useRef<HTMLDivElement | null>(null);
  const titleRef = useRef<HTMLHeadingElement | null>(null);
  const cardRef = useRef<HTMLDivElement | null>(null);
  const imageRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const section = sectionRef.current;
    const title = titleRef.current;
    const card = cardRef.current;
    const image = imageRef.current;

    gsap.fromTo(
      section,
      { opacity: 0 },
      {
        opacity: 1,
        duration: 1,
        scrollTrigger: {
          trigger: section,
          start: "top bottom",
          end: "center center",
          scrub: true,
        },
      }
    );

    gsap.fromTo(
      title,
      { y: 50, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        duration: 1.8,
        scrollTrigger: {
          trigger: title,
          start: "top bottom-=100",
          end: "top center",
          scrub: true,
        },
      }
    );

    gsap.fromTo(
      card,
      { x: -50, opacity: 0 },
      {
        x: 0,
        opacity: 1,
        duration: 1.8,
        scrollTrigger: {
          trigger: card,
          start: "top bottom-=50",
          end: "top center",
          scrub: true,
        },
      }
    );

    gsap.fromTo(
      image,
      { x: 50, opacity: 0 },
      {
        x: 0,
        opacity: 1,
        duration: 1.8,
        scrollTrigger: {
          trigger: image,
          start: "top bottom-=50",
          end: "top center",
          scrub: true,
        },
      }
    );
  }, []);

  return (
    <section
      ref={sectionRef}
      className="relative bg-creamyYellow text-black py-24 overflow-hidden"
    >
      <div className="min-h-screen max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2
          ref={titleRef}
          className="text-4xl sm:text-5xl font-bold mb-16 text-center text-bakedPotato"
        >
          Billing
        </h2>

        {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
          <div ref={cardRef} className="bg-sageGreen p-8 rounded-lg shadow-lg transform transition-all duration-300 hover:scale-105">
            <h3 className="text-3xl font-semibold mb-6 text-forrestGreen">Pricing</h3>
            <ul className="space-y-4 text-lg">
              <li className="flex items-center">
                <span className="text-2xl mr-2">🎁</span> Sign up to get 100 free credits
              </li>
              <li className="flex items-center">
                <span className="text-2xl mr-2">💸</span> 1 rupee per credit via secured payment gateway
              </li>
              <li className="flex items-center">
                <span className="text-2xl mr-2">🔍</span> 30 credits per search
              </li>
            </ul>
            <button className="mt-8 bg-bakedPotato text-black text-xl font-bold py-3 px-6 rounded-full hover:bg-mutedTeal transition duration-300 transform hover:scale-110">
              Sign Up Now
            </button>
          </div>
          
          <div ref={imageRef} className="relative">
            <img src="/potato.gif" alt="Secure Payment" className="rounded-lg" />
            <div className="absolute inset-0  rounded-lg"></div>
            
                  </div>
        
        </div> */}

        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
          <div ref={cardRef}>
            <div className="bg-bakedPotato p-8 rounded-lg shadow-lg transform transition-all duration-300 hover:scale-105 flex flex-col items-center">
              <h3 className="text-3xl font-semibold mb-6 text-tan flex items-center">
                Pricing
              </h3>
              <p className="text-xl mb-4 text-tan text-center">
                🎉 Register free for now! 🎉
              </p>
              <button
                className="mt-8 bg-[#B5CBB7] shadow-xl text-black text-2xl font-bold py-4 px-12 rounded-full transition duration-300 transform hover:scale-110"
                onClick={() => {
                  window.location.href = "/signup";
                }}
              >
                ✍️ Sign Up Now
              </button>
            </div>
          </div>
          <div ref={imageRef} className="relative">
            <img
              src="/richPotato.png"
              alt="Secure Payment"
              className="rounded-lg"
            />
            <div className="absolute inset-0  rounded-lg"></div>
          </div>
        </div>

        <div className="mt-16 text-center">
          <p className="text-2xl text-lightSkin font-semibold">
            Start your legal research journey today with LawPotato!
          </p>
        </div>
      </div>
    </section>
  );
};

export default Billing;
