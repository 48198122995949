import React from "react";

const PotatoFloatSvg: React.FC = () => {
  return (
    <div>
      <div id="potato" className="row justify-content-center">
        <div className="">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 801 600"
            preserveAspectRatio="xMidYMid meet"
            className="w-full "
          >
            <g id="potatoBody">
              <g>
                <ellipse
                  className="st6"
                  cx="400.2"
                  cy="252.2"
                  rx="180"
                  ry="220"
                  fill="#D2B48C"
                  stroke="#8B4513"
                  strokeWidth="4"
                />

                <path
                  fill="#C19A6B"
                  d="M310,90c-50,35-90,100-90,170c0,106.6,86.4,193,193,193c30,0,55-7,80-20c-120-120-140-300-183-343z"
                />

                <g>
                  <ellipse cx="370" cy="180" rx="15" ry="8" fill="#8B4513" />
                  <ellipse cx="420" cy="230" rx="12" ry="6" fill="#8B4513" />
                  <ellipse cx="460" cy="280" rx="18" ry="10" fill="#8B4513" />
                  <ellipse cx="350" cy="320" rx="10" ry="6" fill="#8B4513" />
                </g>

                <g>
                  <ellipse cx="370" cy="220" rx="12" ry="20" fill="#fff" />
                  <ellipse cx="430" cy="220" rx="12" ry="20" fill="#fff" />
                  <ellipse cx="370" cy="220" rx="6" ry="10" fill="#000" />
                  <ellipse cx="430" cy="220" rx="6" ry="10" fill="#000" />

                  <path
                    d="M370 270 Q400 300 430 270 Q400 330 370 270"
                    stroke="#000"
                    strokeWidth="5"
                    fill="transparent"
                  />
                </g>

                <g>
                  <path
                    d="M280,250 Q200,200 190,250"
                    stroke="#8B4513"
                    strokeWidth="10"
                    fill="transparent"
                  />
                  <circle cx="180" cy="250" r="15" fill="#8B4513" />

                  <path
                    d="M520,250 Q600,200 590,250"
                    stroke="#8B4513"
                    strokeWidth="10"
                    fill="transparent"
                  />
                  <circle cx="610" cy="250" r="15" fill="#8B4513" />
                </g>

                <g>
                  <path
                    fill="#8B4513"
                    d="M540,150c3,5,6,10,9,15c3,5,5,10,7,16c2,5,4,11,6,17c1,3,1,6,2,9c1,3,1,6,2,9c2,12,4,24,4,36
                    c0,12-1,24-3,36l-1,5l-1,2l-1,2l-1,5l-2,5c-1,3-2,6-3,9l-2,4c-1,1-1,3-1,3l-2,2c-3,5-6,10-9,15c-1,1-2,3-3,4l-3,4
                    c-2,2-4,5-6,7c-7,9-15,18-25,25c-4,4-9,7-14,10c-5,3-10,6-15,8c8-8,15-16,22-25c7-9,13-18,19-27c6-9,11-19,15-29
                    c1-3,2-5,3-8c1-3,2-5,3-8l1-4l1-4l1-2c5-21,5-43,3-65c0-3,0-6-1-9c-1-3-1-6-1-9c-1-6-2-11-2-17C550,180,548,169,545,158z"
                  />
                </g>
              </g>
            </g>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default PotatoFloatSvg;
