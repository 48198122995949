import React, { useState, useRef, useEffect } from "react";
import { Popover, Transition } from "@headlessui/react";
import { X, Maximize2, Minimize2, Send } from "lucide-react";
import axios from "axios";
import { apiUri } from "../constants";
import LoadingButton from "./LoadingButton";

interface InteractWithCaseProps {
  onClose: () => void;
  document: string;
}

interface Message {
  role: "user" | "assistant";
  content: string;
  timestamp?: string;
}

const InteractWithCase: React.FC<InteractWithCaseProps> = ({
  onClose,
  document,
}) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!input.trim()) return;

    const userMessage: Message = {
      role: "user",
      content: input,
      timestamp: new Date().toLocaleTimeString(),
    };
    setMessages((prev) => [...prev, userMessage]);
    setInput("");
    setIsLoading(true);

    try {
      const response = await axios.post(`${apiUri}/interactWithCase`, {
        question: input,
        caseInput: document,
      });
      const assistantMessage: Message = {
        role: "assistant",
        content: response.data.data,
        timestamp: new Date().toLocaleTimeString(),
      };
      setMessages((prev) => [...prev, assistantMessage]);
    } catch (error) {
      console.error("Error interacting with the case:", error);
      const errorMessage: Message = {
        role: "assistant",
        content:
          "An error occurred while processing your request. Please try again.",
        timestamp: new Date().toLocaleTimeString(),
      };
      setMessages((prev) => [...prev, errorMessage]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Popover>
      <Transition
        show={true}
        enter="transition duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition duration-150 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          static
          className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50"
        >
          <div
            className={`rounded-lg shadow-xl flex flex-col bg-white ${
              isFullScreen
                ? "w-full h-full"
                : "w-11/12 h-5/6 max-w-5xl max-h-[90vh]"
            }`}
          >
            <div className="sticky top-0 flex justify-between items-center p-4 bg-gray-800 text-white rounded-t-lg">
              <div className="flex items-center space-x-2">
                <div className="w-3 h-3 rounded-full bg-green-500"></div>
                <h2 className="text-lg font-semibold">
                  Case Analysis Interface
                </h2>
              </div>
              <div className="flex space-x-2">
                <button
                  onClick={toggleFullScreen}
                  className="p-1 hover:bg-gray-700 rounded-md transition-colors duration-200"
                  aria-label={
                    isFullScreen ? "Exit full screen" : "Enter full screen"
                  }
                >
                  {isFullScreen ? (
                    <Minimize2 className="h-5 w-5" />
                  ) : (
                    <Maximize2 className="h-5 w-5" />
                  )}
                </button>
                <button
                  onClick={onClose}
                  className="p-1 hover:bg-gray-700 rounded-md transition-colors duration-200"
                  aria-label="Close"
                >
                  <X className="h-5 w-5" />
                </button>
              </div>
            </div>

            <div className="flex-grow overflow-y-auto p-6 space-y-4 bg-gray-50">
              {messages.map((message, index) => (
                <div
                  key={index}
                  className={`flex ${
                    message.role === "user" ? "justify-end" : "justify-start"
                  }`}
                >
                  <div
                    className={`max-w-2xl p-4 rounded-lg shadow-sm ${
                      message.role === "user"
                        ? "bg-blue-600 text-white ml-12"
                        : "bg-white text-gray-800 border border-gray-200 mr-12"
                    }`}
                  >
                    <div className="text-sm mb-1">
                      {message.role === "user" ? "Attorney" : "Assistant"} •{" "}
                      {message.timestamp}
                    </div>
                    <div
                      className={`${
                        message.role === "user"
                          ? "text-gray-100"
                          : "text-gray-700"
                      }`}
                    >
                      {message.content}
                    </div>
                  </div>
                </div>
              ))}
              <div ref={messagesEndRef} />
            </div>

            <form
              onSubmit={handleSubmit}
              className="sticky bottom-0 p-4 bg-white border-t border-gray-200 rounded-b-lg"
            >
              <div className="flex space-x-3">
                <input
                  type="text"
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  placeholder="Enter your case-related inquiry..."
                  className="flex-grow p-3 border border-gray-300 rounded-lg bg-white text-gray-800 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
                <button
                  type="submit"
                  disabled={isLoading}
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200"
                >
                  {isLoading ? <LoadingButton /> : <Send className="h-5 w-5" />}
                </button>
              </div>
            </form>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default InteractWithCase;
