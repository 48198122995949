import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import Header from "../components/Header";
import Footer from "../components/Footer";

const ContactUs: React.FC = () => {
  const headingRef = useRef<HTMLHeadingElement>(null);
  const infoRef = useRef<HTMLDivElement>(null);
  // const cursorRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   document.addEventListener("mousemove", (e) => {
  //     gsap.to(cursorRef.current, {
  //       x: e.clientX,
  //       y: e.clientY,
  //       duration: 0.3,
  //       ease: "power2.out",
  //     });
  //   });
  // }, []);

  useEffect(() => {
    const tl = gsap.timeline();

    tl.from(headingRef.current, {
      opacity: 0,
      y: -30,
      duration: 1,
      ease: "power2.out",
    }).from(
      infoRef.current,
      {
        opacity: 0,
        scale: 0.9,
        duration: 1,
        ease: "power2.out",
      },
      "-=0.5"
    );
  }, []);

  return (
    <div>
      {/* <div
        ref={cursorRef}
        className="fixed w-6 h-6 rounded-full bg-bakedPotato mix-blend-difference  z-50"
      ></div> */}
      <Header />
      <div className="bg-bakedPotato text-white min-h-screen flex flex-col justify-center items-center py-10">
        <h1
          ref={headingRef}
          className="text-4xl sm:text-6xl font-bold mb-8 text-center"
        >
          Contact Us 🥔
        </h1>

        <div ref={infoRef} className="max-w-4xl w-full">
          <div className="text-center mb-12">
            <span className="text-2xl text-white">
              Our team is available to assist you with any inquiries.
            </span>
          </div>

          <div className="grid md:grid-cols-3 gap-8">
            <div className="flex flex-col items-center p-6 rounded-lg shadow-md hover:shadow-xl transition-transform transform hover:rotate-3 bg-lightSkin">
              <svg
                viewBox="0 0 1024 1024"
                className="w-12 h-12 text-creamyYellow mb-4"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M854 342v-86l-342 214-342-214v86l342 212zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"></path>
              </svg>
              <div className="text-center">
                <h3 className="text-2xl font-semibold">Email</h3>
                <p className="mt-2 text-lg text-creamyYellow">
                  Feel free to contact us via email!
                </p>
                <span className="block mt-2 text-xl text-gray-700">
                  <a
                    href="mailto:adyityasingh@gmail.com"
                    className="text-white"
                  >
                    adyityasingh@gmail.com
                  </a>
                </span>
              </div>
            </div>

            <div className="flex flex-col items-center p-6 rounded-lg shadow-md hover:shadow-xl transition-transform transform hover:rotate-3 bg-lightSkin">
              <svg
                viewBox="0 0 1024 1024"
                className="w-12 h-12 text-creamyYellow mb-4"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M282 460q96 186 282 282l94-94q20-20 44-10 72 24 152 24 18 0 30 12t12 30v150q0 18-12 30t-30 12q-300 0-513-213t-213-513q0-18 12-30t30-12h150q18 0 30 12t12 30q0 80 24 152 8 26-10 44z"></path>
              </svg>
              <div className="text-center">
                <h3 className="text-2xl font-semibold">Phone</h3>
                <p className="mt-2 text-lg text-creamyYellow">
                  We look forward to hearing from you!
                </p>
                <span className="block mt-2 text-xl text-white">
                  <a href="tel:+918383990480" className="text-white">
                    +91 8383990480
                  </a>
                </span>
              </div>
            </div>

            <div className="flex flex-col items-center p-6 rounded-lg shadow-md hover:shadow-xl transition-transform transform hover:rotate-3 bg-lightSkin">
              <svg
                viewBox="0 0 1024 1024"
                className="w-12 h-12 text-creamyYellow mb-4"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M512 0c-176.732 0-320 143.268-320 320 0 320 320 704 320 704s320-384 320-704c0-176.732-143.27-320-320-320zM512 512c-106.040 0-192-85.96-192-192s85.96-192 192-192 192 85.96 192 192-85.96 192-192 192z"></path>
              </svg>
              <div className="text-center">
                <h3 className="text-2xl font-semibold">Office</h3>
                <p className="mt-2 text-lg text-creamyYellow">
                  Pay us a visit!
                </p>
                <span className="block mt-2 text-xl text-white">
                  SPD Plaza, Koramangala Industrial Layout, 5th Block,
                  Koramangala, Bengaluru, Karnataka 560034
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactUs;
