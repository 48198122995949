import React, { useEffect, useRef } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import gsap from "gsap";

const TermsandCond: React.FC = () => {
  // const cursorRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   document.addEventListener("mousemove", (e) => {
  //     gsap.to(cursorRef.current, {
  //       x: e.clientX,
  //       y: e.clientY,
  //       duration: 0.3,
  //       ease: "power2.out",
  //     });
  //   });
  // }, []);
  return (
    <div>
      {/* <div
        ref={cursorRef}
        className="fixed w-6 h-6 rounded-full bg-bakedPotato mix-blend-difference  z-50"
      ></div> */}
      <Header />
      <div className="p-6 mx-auto bg-bakedPotato dark:bg-gray-900 text-black dark:text-gray-200">
        <h1 className="text-4xl font-bold mb-6 text-center">
          Terms and Conditions
        </h1>

        <p className="mb-4">
          These terms and conditions set forth the legally binding terms and
          conditions for your use of the site at{" "}
          <a
            href="https://www.lawPotato.com"
            className="text-[#B5CBB7] hover:underline"
          >
            https://www.lawPotato.com
          </a>
          , operated by TAK Law Labs AI.
        </p>

        <p className="mb-4">
          The information and services offered by or through the website are
          made available subject to these Terms and Conditions. By using the
          website, you agree to be bound by, and to comply with, these Terms and
          Conditions and any further terms and conditions that TAK Law Labs AI
          may implement from time to time. TAK Law Labs AI reserves the right to
          make changes to this site and these disclaimers, terms, and conditions
          at any time without prior notice.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">
          AI Model Disclaimer
        </h2>
        <p className="mb-4">
          TAK Law Labs AI uses AI models to provide various services and
          information on this site. Please note that the AI model may sometimes
          provide incorrect information, may hallucinate, or produce unexpected
          results. TAK Law Labs AI does not make any guarantees about the
          accuracy or reliability of the AI-generated outputs and disclaims any
          responsibility for actions taken based on the information provided by
          the AI.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">
          GIFs and Resources Disclaimer
        </h2>
        <p className="mb-4">
          The GIFs displayed on the site are sourced from Pixabay and are
          believed to be in the public domain. If you believe that any of the
          GIFs are owned by you and have been used without proper authorization,
          please contact us at{" "}
          <a
            href="mailto:support@lawpotato.com"
            className="text-[#B5CBB7] hover:underline"
          >
            support@lawpotato.com
          </a>
          , and we will promptly remove them.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">
          Copyright and Intellectual Property
        </h2>
        <p className="mb-4">
          All contents included on this website, such as text, graphics, logos,
          button icons, images (except those under the public domain), audio
          clips, databases, and software, are the property of TAK Law Labs AI.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">
          Liability Disclaimer
        </h2>
        <p className="mb-4">
          The information, content, services, products, and materials published
          on this website, including without limitation text, graphics, and
          links, are provided on an “as is” basis. TAK Law Labs AI makes no
          representations or warranties of any kind, express or implied, as to
          the operation of this website or the accuracy, correctness, or
          completeness of the information, contents, materials, or products
          included on this website. Without limiting the generality of the
          aforegoing:
        </p>
        <ul className="list-disc ml-6 mb-4">
          <li>
            TAK Law Labs AI does not warrant that this website will be
            error-free, or will meet any particular criteria of accuracy,
            completeness, or reliability of information, performance, or
            quality.
          </li>
          <li>
            Whilst TAK Law Labs AI has taken reasonable measures to ensure the
            integrity of this website and its contents, no warranty, express or
            implied, is given that any files, downloads, or applications
            available via this website are free of viruses, trojans, bombs,
            time-locks, or any other code which could corrupt or affect the
            operation of your system.
          </li>
          <li>
            TAK Law Labs AI accepts no liability in respect of losses or damages
            arising out of changes made to the content of this website by
            unauthorized third parties.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mt-8 mb-4">
          Limitation of Liability
        </h2>
        <p className="mb-4">
          In no event shall TAK Law Labs AI be liable for any damages, losses,
          or liabilities, including without limitation direct or indirect,
          special, incidental, consequential damages, losses, or liabilities, in
          connection with your use of this site or any failure of performance,
          error, omission, interruption, defect, delay in operation,
          transmission, computer virus, or system failure. TAK Law Labs AI is
          not responsible for the content of any linked websites and accepts no
          liability with respect thereto or for any reliance placed upon such
          linked websites.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">
          Professional Information
        </h2>
        <p className="mb-4">
          While every effort is made to regularly update the information on this
          site and to offer the most current, correct, and clearly expressed
          information possible, there may be instances where such information
          proves inaccurate or incomplete. Before placing any reliance on the
          data and information provided on this site, please consult TAK Law
          Labs AI directly or approach your own professional advisors.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">E-mail</h2>
        <p className="mb-4">
          Information contained in any e-mail message and all/any attachments
          thereto from TAK Law Labs AI may contain both confidential and private
          information intended for a specific addressee and sent for a specific
          purpose. If you are not the addressee, you may not disclose, copy,
          distribute, or take any action based on the contents hereof. You are
          kindly requested to inform the sender immediately and destroy all
          copies hereof.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">Privacy Policy</h2>
        <p className="mb-4">
          TAK Law Labs AI does not store any personal data from users of this
          site. We are dedicated to maintaining the privacy of our online
          visitors and users.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">Modifications</h2>
        <p className="mb-4">
          TAK Law Labs AI reserves the right, at its sole discretion, to modify
          or replace these Terms and Conditions by posting the updated terms on
          the site. Your continued use of the site after any such changes
          constitutes your acceptance of the new Terms and Conditions.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">Entire Agreement</h2>
        <p className="mb-4">
          These Terms and Conditions, together with the Privacy Policy and any
          amendments and additional agreements you may enter into with TAK Law
          Labs AI, shall constitute the entire agreement between you and TAK Law
          Labs AI concerning the use of the website.
        </p>

        <p className="mb-4">
          If you have any concerns about material/content that appears on our
          website, please write to us or send us an email at{" "}
          <a
            href="mailto:support@lawpotato.com"
            className="text-[#B5CBB7] hover:underline"
          >
            support@lawpotato.com
          </a>
          .
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default TermsandCond;
