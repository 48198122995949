import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const HowItWorks: React.FC = () => {
  const sectionRef = useRef<HTMLDivElement | null>(null);
  const featureRefs = useRef<(HTMLDivElement | null)[]>([]);
  const textRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const section = sectionRef.current;
    const features = featureRefs.current;

    gsap.fromTo(
      section,
      { opacity: 0, y: 100 },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        scrollTrigger: {
          trigger: section,
          start: "top bottom-=100",
          end: "top center",
          scrub: true,
        },
      }
    );

    gsap.fromTo(
      textRef.current,
      { y: -20, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        duration: 1,
        ease: "bounce.out",
        repeat: -1,
        yoyo: true,
      }
    );

    features.forEach((feature, index) => {
      gsap.fromTo(
        feature,
        { opacity: 0, x: index % 2 === 0 ? -50 : 50 },
        {
          opacity: 1,
          x: 0,
          duration: 0.8,
          scrollTrigger: {
            trigger: feature,
            start: "top bottom-=50",
            end: "top center",
            scrub: true,
          },
        }
      );
    });
  }, []);

  const features = [
    {
      title: "Upload Case Files",
      description:
        "Retrieve historical case records from the Indian judiciary, presented in a streamlined, user-friendly format.",
      icon: "📁",
    },
    {
      title: "Engage with the Case",
      description:
        "Ask questions about the case, and let Potato deliver precise, AI-driven answers instantly.",
      icon: "💬",
    },
    {
      title: "Access Full Judgments",
      description:
        "Effortlessly view and refer to complete legal judgments for thorough analysis and reference.",
      icon: "📚",
    },
  ];

  return (
    <section ref={sectionRef} className="bg-creamyYellow text-black py-24">
      <div className="min-h-screen max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-4xl sm:text-5xl font-bold mb-16 text-center text-lightSkin">
          How it Works
        </h2>
        <div className="flex flex-col items-center mb-8">
          <div className="relative w-64 h-64">
            <img
              src="/angryPotato.png"
              alt="Angry Potato"
              className="w-full h-full"
            />
          </div>

     
          <div className="relative mt-4 max-w-lg">
            <div className="bg-bakedPotato text-tan text-2xl font-bold p-6 rounded-xl shadow-lg relative text-center">
       
              "How do you even work, potato? You're starting to look more like
              an egg with each failed attempt!"
            </div>

            <div className="absolute left-1/2 transform -translate-x-1/2 bottom-[-20px] w-0 h-0 border-l-[20px] border-r-[20px] border-t-[20px] border-l-transparent border-r-transparent border-t-bakedPotato"></div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          {features.map((feature, index) => (
            <div
              key={feature.title}
              ref={(el) => (featureRefs.current[index] = el)}
            >
              <div className="relative group bg-lightSkin p-8 rounded-lg transform transition-all duration-300 hover:scale-105 overflow-hidden">
                <div className="text-6xl mb-6">{feature.icon}</div>
                <h3 className="text-3xl font-semibold mb-4 text-creamyYellow">
                  {feature.title}
                </h3>
                <p className="text-white text-lg">{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
