import React, { useState } from "react";
import { Upload, Search, Clock, X, ChevronRight } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";

const HowToUseGuide = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    {
      title: "Upload Your Case Files",
      description: "Simply drag and drop or select your case file (up to 5MB)",
      icon: <Upload className="h-6 w-6 text-blue-600" />,
      detail: "We support various file formats including PDF and DOC files.",
    },
    {
      title: "Automated Analysis",
      description: "Our AI processes your case and extracts key information",
      icon: <Search className="h-6 w-6 text-blue-600" />,
      detail:
        "No manual input needed - we'll identify the important keywords and themes.",
    },
    {
      title: "Get Instant Results",
      description: "Review the 10 most relevant matching cases",
      icon: <Clock className="h-6 w-6 text-blue-600" />,
      detail: "Results are ranked by relevance and similarity to your case.",
    },
  ];

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 flex items-center justify-center bg-black/50 backdrop-blur-sm p-4 z-50"
        >
          <motion.div
            initial={{ scale: 0.9, y: 20 }}
            animate={{ scale: 1, y: 0 }}
            exit={{ scale: 0.9, y: 20 }}
            className="w-full max-w-4xl bg-gradient-to-br from-bakedPotato to-creamyYellow rounded-xl shadow-2xl overflow-hidden"
          >
            {/* Header */}
            <div className="border-b bg-lightSkin p-4 md:p-6">
              <div className="flex justify-between items-center">
                <motion.h2
                  initial={{ x: -20 }}
                  animate={{ x: 0 }}
                  className="text-2xl md:text-3xl font-bold text-white flex items-center gap-3"
                >
                  <span>Let's get COOKING !!!</span>
                  <span className="animate-bounce text-2xl">🚀</span>
                </motion.h2>
                <motion.button
                  whileHover={{ rotate: 90 }}
                  whileTap={{ scale: 0.9 }}
                  onClick={() => setIsOpen(false)}
                  className="p-2 rounded-full hover:bg-gray-600/20 transition-colors"
                >
                  <X className="h-5 w-5 text-white" />
                </motion.button>
              </div>
            </div>

            {/* Content */}
            <div className="p-4 md:p-6">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                {/* Steps */}
                <div className="space-y-4">
                  {steps.map((step, index) => (
                    <motion.div
                      key={index}
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: index * 0.1 }}
                      onHoverStart={() => setActiveStep(index)}
                      className={`flex items-start space-x-4 p-4 rounded-lg transition-all duration-300 cursor-pointer
                        ${
                          activeStep === index
                            ? "bg-white/20 shadow-lg transform scale-105"
                            : "hover:bg-white/10"
                        }`}
                    >
                      <motion.div
                        whileHover={{ rotate: 360 }}
                        transition={{ duration: 0.5 }}
                        className="flex-shrink-0 w-12 h-12 flex items-center justify-center rounded-full bg-blue-100"
                      >
                        {step.icon}
                      </motion.div>
                      <div className="flex-1">
                        <h3 className="font-semibold text-lg text-white">
                          {step.title}
                        </h3>
                        <p className="text-black font-medium mt-1">
                          {step.description}
                        </p>
                        <p className="text-sm text-gray-700 font-medium mt-2">
                          {step.detail}
                        </p>
                      </div>
                    </motion.div>
                  ))}
                </div>

                {/* Interactive Demo */}
                <div className="relative hidden lg:block">
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="aspect-square rounded-lg overflow-hidden flex items-center justify-center bg-white/10 p-4"
                  >
                    <img
                      src="/logo.png"
                      alt="Interactive demo"
                      className="w-full h-full object-contain rounded-lg hover:scale-105 transition-transform duration-300"
                    />
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      className="absolute bottom-0 left-0 right-0 p-6 bg-gradient-to-t from-black/80 via-black/50 to-transparent"
                    >
                      <p className="text-white text-lg font-medium">
                        Try it now - Upload your first case file
                      </p>
                    </motion.div>
                  </motion.div>
                </div>
              </div>

              {/* Footer */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="mt-8 pt-6 border-t border-white/20"
              >
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={() => setIsOpen(false)}
                  className="w-full md:w-auto px-8 py-3 bg-lightSkin text-white rounded-lg hover:bg-gray-800 
                    transition-colors flex items-center justify-center space-x-2 font-semibold text-lg"
                >
                  <span>LET'S GOOO !!</span>
                  <ChevronRight className="h-5 w-5" />
                </motion.button>
              </motion.div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default HowToUseGuide;
