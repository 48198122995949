import React, { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Alert from "../components/Alert";
import axios from "axios";
import { apiUri } from "../constants";

gsap.registerPlugin(ScrollTrigger);

const SignUpPage: React.FC = () => {
  // const cursorRef = useRef<HTMLDivElement>(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState(false);

  // useEffect(() => {
  //   document.addEventListener("mousemove", (e) => {
  //     gsap.to(cursorRef.current, {
  //       x: e.clientX,
  //       y: e.clientY,
  //       duration: 0.3,
  //       ease: "power2.out",
  //     });
  //   });
  // }, []);

  useEffect(() => {
    gsap.from(".signup-heading", {
      y: -50,
      opacity: 0,
      duration: 1.2,
      ease: "bounce.out",
    });
    gsap.from(".signup-form", {
      opacity: 0,
      duration: 1.5,
      delay: 0.3,
      ease: "power1.out",
    });
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setAlertMessage("Passwords don't match!");
      setAlertSuccess(false);
      setShowAlert(true);
      return;
    }

    try {
      const response = await axios.post(`${apiUri}/lawyerSignup`, {
        name: name,
        email: email,
        password: password,
      });

      if (response.data.success == true) {
        setAlertMessage("Signup successful! Redirecting to login...");
        setAlertSuccess(true);
        setShowAlert(true);

        setTimeout(() => {
          window.location.href = "/login";
        }, 3000);
      }
    } catch (error) {
      setAlertMessage("Email already in use or an internal error occured");
      setAlertSuccess(false);
      setShowAlert(true);
    }
  };

  return (
    <div className="min-h-screen bg-bakedPotato flex flex-col items-center justify-center">
      {/* <div
        ref={cursorRef}
        className="fixed w-6 h-6 rounded-full bg-bakedPotato mix-blend-difference  z-50"
      ></div> */}
      <div className="w-full">
        <Header />
      </div>

      <div className="flex flex-col lg:flex-row items-center justify-center w-full max-w-6xl mx-auto mt-8 mb-8">
        <div className="w-full lg:w-1/2 max-w-md bg-tan p-8 rounded-xl shadow-lg">
          <h2 className="signup-heading text-4xl font-bold text-center text-bakedPotato mb-6">
            🍽️ Get Cooking with LawPotato!
          </h2>
          <p className="text-lg text-center text-forrestGreen mb-10">
            Enter your ingredients below and let's start cooking up some legal
            research magic! 🔍🥔
          </p>

          {showAlert && (
            <Alert message={alertMessage} onClose={() => setShowAlert(false)} />
          )}

          <form className="signup-form space-y-6" onSubmit={handleSubmit}>
            <div>
              <label
                htmlFor="name"
                className="block text-lg font-semibold text-bakedPotato mb-2"
              >
                Your Name (Chef's Name) 🧑‍🍳
              </label>
              <input
                type="text"
                id="name"
                placeholder="Enter your name"
                className="w-full border border-gray-300 rounded-lg p-3 focus:ring-2 focus:ring-sageGreen"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>

            <div>
              <label
                htmlFor="email"
                className="block text-lg font-semibold text-bakedPotato mb-2"
              >
                Email (Kitchen Email) ✉️
              </label>
              <input
                type="email"
                id="email"
                placeholder="Enter your email"
                className="w-full border border-gray-300 rounded-lg p-3 focus:ring-2 focus:ring-sageGreen"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-lg font-semibold text-bakedPotato mb-2"
              >
                Secret Recipe (Password) 🔑
              </label>
              <input
                type="password"
                id="password"
                placeholder="Enter your secret recipe"
                className="w-full border border-gray-300 rounded-lg p-3 focus:ring-2 focus:ring-sageGreen"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>

            <div>
              <label
                htmlFor="confirm-password"
                className="block text-lg font-semibold text-bakedPotato mb-2"
              >
                Confirm Your Recipe 🍲
              </label>
              <input
                type="password"
                id="confirm-password"
                placeholder="Re-enter your secret recipe"
                className="w-full border border-gray-300 rounded-lg p-3 focus:ring-2 focus:ring-sageGreen"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>

            <div className="flex justify-center">
              <button
                type="submit"
                className="bg-bakedPotato text-white text-xl font-bold py-3 px-8 rounded-full hover:bg-mutedTeal transition-all transform hover:scale-110"
              >
                🥔 Mash It Up!
              </button>
            </div>
          </form>

          <p className="text-center text-gray-600 mt-6">
            Already have an account?{" "}
            <a
              href="/login"
              className="text-forrestGreen font-bold hover:text-sageGreen"
            >
              Login here!
            </a>
          </p>
        </div>

        {/* <div className="w-full lg:w-1/2 flex items-end justify-end ">
            <img
                src="potato.gif"
                alt="Potato GIF"
                className="w-full h-auto max-w-lg object-cover"
            />
        </div> */}
      </div>

      <div className="w-full">
        <Footer />
      </div>
    </div>
  );
};

export default SignUpPage;
